import React, { useState } from 'react';
import { Button, Heading, Input, Grid, Link, Text } from '@chakra-ui/react';
import { useForm, SubmitHandler } from "react-hook-form";
import { authStore } from '../store/AuthStore';
import edmontonBanner from '../assets/edmonton-banner.jpg';

type Inputs = {
  username: string
  password: string
}

export const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
  } = useForm<Inputs>()
  const [error, setError] = useState<undefined | string>(undefined)
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.username && data.password) {
      const response = await authStore.reFetchUser(data.username);
      if (response) {
        const redirect = localStorage.getItem('redirect_after_login');
        if (redirect) {
          localStorage.removeItem('redirect_after_login');
          document.location.href = redirect;
        } else {
          document.location.pathname = '/';
        }
      } else {
        setError(/*response.error*/'oppsie');
      }
    }
  }
  return (
    <Grid gap="1.5em" bg="brand.100" placeContent="start center" w="100%" h="100%" paddingTop="1em" alignItems="start" backgroundImage={edmontonBanner}>
      <Heading letterSpacing="-1px" color="brand.500" size="xs">Sign in to your Account</Heading>
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='Email*' type="email" required {...register('username')} />
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='Password*' type="password" required {...register('password')} />
      {error && <Text color="red.400">{error}</Text>}
      <Link justifySelf="end" type="reset">Forgot?</Link>
      <Button
        color="white"
        onClick={handleSubmit(onSubmit)}
        isLoading={authStore.isLoading}
        loadingText="Loading..."
        variant="solid"
      >
        Sign In
      </Button>
      <Link href='/signup' textAlign="center" type="reset">Create an Account</Link>
    </Grid>
  )
}